import type { Page } from '~~/types'

export default (opts?: Record<string, any>) => {
  const { $routes } = useNuxtApp()

  
  function getPageSlug(route) {
    const pages = route.params.page
    if (Array.isArray(pages))
      return pages.filter((f) => !!f).pop()
    
    if (!pages)
      return 'home'
    
    return pages
  }

  const slug = computed(() => getPageSlug(useRoute()))

  const { transform, theme } = usePagesBody()

  function fetchPage() {
    return useSanityQuery<Page>(
      useDghGroq().page,
      { slug: slug.value },
      {
        // @ts-ignore
        transform,
      }
    )
  }

  definePageMeta({
    validate: (route) => {
      const slug = getPageSlug(route)
        return (
          !!$routes['page_' + slug] ||
          !!$routes['article_' + slug] ||
          !!$routes['story_' + slug]
        )
    }
  })

  return {
    getPageSlug,
    slug,
    fetchPage,
    theme,
  }
}