<template>
  <main
    id="main"
    class="page"
    :class="[
      `page-${slug}`,
      { 'page-alt-layout theme-alt': page && page.options.altLayout },
    ]"
  >
    <CSiteScrollDetector />
    <AtomsLoader v-if="status === 'pending' || !page" class="page-loader" />
    <TransitionGroup v-else>
      <PageHeader key="header" v-bind="page.heading" />
      <BlocksSection
        v-for="section in page.body"
        :key="section._key"
        v-bind="section"
      />
      <SiteFooter key="footer" />
    </TransitionGroup>
  </main>
</template>

<script lang="ts" setup>
const { slug, fetchPage, theme } = usePages()

// const preview = computed(() => Object.keys(query).includes('preview'))

// const searchQuery = computed(() => preview.value ? `_id == $slug` : `slug.current == $slug`)

const { data: page, status } = fetchPage()

onMounted(() => {
  if (!document) return
  useNuxtApp().hook('page:finish', () => {
    console.log(
      'rect',
      document.getElementById('key-documents')?.getBoundingClientRect()
    )
  })
})

watch(
  slug,
  () => (theme.value = page.value?.options.altLayout ? 'alt' : ''),
  { immediate: true }
)

// useHead(() => page.value?.head)
</script>

<style lang="scss">
.page {
  position: relative;

  &-loader {
    margin: auto;
  }

  &-cards .container {
    width: fit-content;
    .column {
      max-width: 20rem;
    }

    grid-gap: 2rem;
    justify-content: center;

    .second {
      margin-top: 5vh;
    }
    .third {
      margin-top: 10vh;
    }
  }
}
</style>
